<template lang="pug">
.ui-checkbox
  label(:class="{ 'ui-checkbox--disabled': disabled, 'ui-checkbox--checked': isChecked }")
    input(type="checkbox" :disabled="disabled" v-model="isChecked" @change="emitValue")
    .ui-checkbox-icon
      ui-icon(v-if="isChecked" name="check-s" size=20)
    slot
  span.ui-checkbox--error-message(v-if="errors.length" v-for="error in errors") {{ error }}
</template>

<script>
export default {
  props: {
    value: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    errors: { type: Array, default: () => [] },
  },
  emits: ['update:modelValue'],

  // eslint-disable-next-line require-jsdoc
  data() {
    return {
      isChecked: this.value,
    };
  },

  methods: {
    emitValue() {
      if (!this.disabled) {
        this.$emit('update:modelValue', this.isChecked);
      }
    },
  },
};
</script>

<style lang="scss">
.ui-checkbox {
  label {
    display: inline-block;
    position: relative;
    padding-left: 28px;
    margin-right: 8px;
    cursor: pointer;
    // user-select: none;
    transition: all 0.3s ease;
  }

  &--error-message {
    display: block;
    color: $red;
    font-size: 14px;
    margin-top: 4px;
  }

  &-icon {
    position: absolute;
    top: 4px;
    left: 0;
    width: 20px;
    height: 20px;
    border: 1px solid $gray40;
    border-radius: 3px;

    svg {
      transform: translate(2px, -2px);
    }
  }

  input[type='checkbox'] {
    display: none; /* Скрыть стандартный чекбокс */
  }

  &--checked .ui-checkbox-icon {
    background-color: white;
    border: 1px solid $gray80;
  }

  &--disabled {
    cursor: not-allowed;

    .ui-checkbox-icon {
      background: $gray20;
    }
  }
}
</style>
