<template lang="pug">
.ui-textarea(v-html="text" readonly @scroll="checkScrollEnd")
</template>

<script>
export default {
  props: {
    text: { type: String, required: true },
    scrollEnd: { type: Boolean, default: false },
  },
  emits: ['scrolled'],

  methods: {
    checkScrollEnd() {
      const textarea = this.$el;
      if (textarea && this.scrollEnd) {
        // Проверяем, достигнут ли конец текста
        if (textarea.scrollTop + textarea.clientHeight >= textarea.scrollHeight) {
          this.$emit('scrolled');
        }
      }
    },
  },
};
</script>

<style lang="scss">
.ui-textarea {
  overflow: scroll;
  border-radius: 4px;
  border: 1px solid $gray40;
  resize: none;
  width: 100%;
  height: 250px;
  padding: 15px 25px;
  scrollbar-width: thin; /* Тонкий бегунок */
  scrollbar-color: $gray40 transparent; /* Цвет бегунка и фона */

  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;

  /* Стили бегунка прокрутки */
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $gray40;
    border-radius: 2px;
  }

  strong {
    font-weight: 700;
    display: block;
  }
}
</style>
